img{
    width: 31.5rem;
    height: 47.3rem;
    border-radius: 1rem;
}


.image{
    width: 31.4rem;
    height: 46.4rem;
}


@media screen and (max-width: 930px){
    .image{
        width: 29rem;
    }
    
    img{
        width: 29rem;
    }
    
}