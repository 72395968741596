@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;500;700&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Red Hat Display', sans-serif;
}

html{
    height: 100%;
}

body{
    min-height: 100%;
}

html{
    font-size:  62.5%;
}

body{
    background-color: #0E162D;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

nav{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.App-header{
    word-break: break-all;
}

.App{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.cards{
    max-width: 120rem;
   /* height: 520px;*/
    display: grid;
    grid-template-columns: 31.5rem 1fr 28.2rem;
    gap: 2.4rem;
}

@media screen and (max-width: 1200px){
    .cards{
        grid-template-columns: 31.5rem 1fr;
    }
}
 
@media screen and (max-width: 930px){
    .cards{
        max-width: 29rem;
        grid-template-columns: 29rem;
    }

    .App-header{
        justify-content: center;
        display: flex;
    }

    .App-header h1{
        font-size: 4.4rem;
    }
    body{
        padding-bottom: 5rem;
    }
}

.card{
    background: #1E293B;
    border-radius: 1rem;
    filter: drop-shadow(0.8rem 0.8rem 0rem rgba(49, 68, 98, 0.25));
}

h1{
    color: #38BDF8;
    font-size: 4.4rem;
}

.btn{
    background-color: #0E162D;
}

.main-div{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

a{
    text-decoration: none;

}

input{
    background: #1e293b;
    border: none;
    border-radius: 1rem;
    color: #fff;
    caret-color: #38BDF8;
}