
.modalContentForm{
    margin-bottom: 2rem;

}

.modalContentForm form{
    display: grid;
    gap: 1rem
}

form div{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.modalContentForm label{
    color: #FFF;
    font-size: 1.4rem;
}


.inputText{
    width: 30%;
    height: 3rem;
    padding : 0.5rem;
}