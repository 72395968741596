.contentLinks {
  width: 55.5rem;
  height: 40rem;
  padding: 2rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: inherit;

  overflow: auto;
  scrollbar-color: #0E162D #1E293B;

}

@media screen and (max-width: 930px){
  .contentLinks{
    max-width: 29rem;
    grid-template-columns: 1fr ;
  }
}


.contentLinks::-webkit-scrollbar-thumb {
  background-color: #0E162D;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;  
}

.contentLinks::-webkit-scrollbar {
width: 16px;
}

.contentLinks::-webkit-scrollbar-track {
background: #1E293B ;
}