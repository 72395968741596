.search{
  padding-left: 1rem;
  display: flex;
  gap: 1rem;
}

.icons{
  color: #38BDF8;
}

.searchbutton {
  background-color: #1e293b;
}

button {
  border: none;
  cursor: pointer;
  color: #fff;
}
:focus{outline: none;}

.inputSearch{
  width: 48.7rem;
  height: 5.4rem;
}



@media screen and (max-width: 930px) {
  .inputSearch {
    width: 28rem;
    height: 5.4rem;
  }
}
