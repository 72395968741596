.dataWeather{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: space-between;
}


@media screen and (max-width: 1200px){
    .dataWeather{
        grid-column: span 2; 
        flex-direction: row;
        justify-content: right;
    }
}

@media screen and (max-width: 930px){
    .dataWeather{
        grid-column: span 1;
        flex-direction: column;
    }
}
