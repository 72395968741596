.cardHeader{
  word-break: break-all;
  margin-bottom: 1rem;
}

h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 4rem;

  color: #38bdf8;
}
ul a:link,
ul a:visited {
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 3rem;
  padding-left: 1rem;
  color: #ffffff;
}

ul a:focus, ul a:hover {
  background-color: #38bdf8;
  border-radius: 1rem;
  border-color: #0E162D;
  color: #0E162D;
}

ul a:focus::before, ul a:hover::before {
  content:"->";
  color: #0E162D;
  font-family: 'Fira Code', monospace;
}

ul {
  list-style: none;
  padding: 0;
  height: 13rem;
  
  display: flex;
  flex-direction: column;
  
  overflow: auto;
  word-break: break-all;
  scrollbar-color: #0E162D #1E293B;
}

ul::-webkit-scrollbar-thumb {
    background-color: #0E162D;
    border: 4px solid transparent;
    border-radius: 1rem;
    background-clip: padding-box;  
}
  
ul::-webkit-scrollbar {
  width: 1.6rem;
}

  
ul::-webkit-scrollbar-track {
  border-radius: 1rem;
  background: #1E293B ;
  
}
